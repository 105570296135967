import {
  Button,
  Divider,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalFooterVariants,
  EModalSize,
  EModalVariants,
  Heading,
  Modal,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getListingData } from '@/redux/selectors/listing/page';

interface IGettingRVOptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  footerMessage?: React.ReactNode;
}

export const GettingRVOptionModal = ({
  isOpen,
  onClose,
  children,
  onSubmit,
  isLoading,
  isDisabled,
  footerMessage,
}: IGettingRVOptionModalProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const listingData = useSelector(getListingData);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant={isMobile ? EModalVariants.Bottom : undefined}
      size={EModalSize.Medium}>
      <Modal.Actions />
      <Modal.Header>
        <Heading variant={EHeadingStyleVariant.H5}>
          {listingData?.disallow_movement ? (
            <FormattedMessage
              defaultMessage="Getting it set up"
              id="olmuHr"
              description="UI > Delivery > Setup only title"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Getting the RV"
              id="7y7v3E"
              description="UI > Delivery > Title"
            />
          )}
        </Heading>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Divider className="mt-8" />
      <Modal.Footer
        variant={EModalFooterVariants.Actions}
        className={`flex-col !items-start md:!items-center md:flex-row flex-nowrap gap-8 ${!footerMessage ? 'md:!justify-end' : ''}`}>
        {footerMessage}
        <Button
          label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
          onClick={onSubmit}
          variant={EButtonColorVariant.Primary}
          loading={isLoading}
          disabled={isDisabled}
          className="w-full md:w-auto"
        />
      </Modal.Footer>
    </Modal>
  );
};
