import 'focus-visible';
import './_app.bonfire.css';
import './_app.styles.css';

import { BonfireProvider } from '@outdoorsyco/bonfire';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';
import querystring from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { IntlProvider, RawIntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import TrackUserAlias from '@/components/auth/TrackUserAlias';
import GoogleAds from '@/components/blog/GoogleAds';
import ApplicationLayout from '@/components/common/ApplicationLayout';
import { ExternalFonts } from '@/components/common/ExternalFonts';
import { OneTrust } from '@/components/OneTrust/OneTrust';
import { RiskifiedScript } from '@/components/riskified/RiskifiedScript';
import SiftTracker from '@/components/sift/Sift';
import ToastProvider from '@/components/switchback/Toast/ToastProvider';
import TermsAndConditionsModalContainer from '@/components/terms-and-conditions/TermsAndConditionsModalContainer';
import { locales } from '@/config/locales';
import { AppRouteProvider } from '@/context/AppRouteContext';
import { Global } from '@/graphql/components/common/Global';
import ApolloClient from '@/graphql/services/ApolloClient';
import { useAttentiveScript } from '@/hooks/useAttentiveScript';
import useQueryParams from '@/hooks/useQueryParams';
import { useRouter } from '@/hooks/useRouter';
import useWindowSize from '@/hooks/useWindowSize';
import { fetchUser } from '@/redux/modules/auth';
import { trackSocialLogin } from '@/redux/modules/auth/login';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { fetchUserSkipped } from '@/redux/modules/auth/userMini';
import { loadCookies } from '@/redux/modules/cookies';
import { setQueryParam } from '@/redux/modules/queryParams';
import { withReduxStore } from '@/redux/store';
import { setODNEventProperties } from '@/services/analytics/campground/util';
import { EFeedbackEventSource, trackFeedbackCTASelectedEvent } from '@/services/analytics/feedback';
import {
  getCampgroundPageType,
  parsePageMetaData,
  storePageMetaData,
} from '@/services/analytics/pageEventUtils';
import { EProjectSection } from '@/services/analytics/trackEventV2';
import setupDatadogRum from '@/services/datadogRum';
import setupOptimizely, { setOptimizelyUser } from '@/services/experiments';
import { getODAnonymousId, getSegment, trackPageView } from '@/services/segment';
import GTMScript from '@/utility/components/GTMScript';
import { getCookie, setCookie } from '@/utility/cookie';
import { getLocale, setupIntl } from '@/utility/i18n';
import { isCI } from '@/utility/isCI';
import { isDev, isSSR } from '@/utility/isSSR';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@/utility/localstorage';
import { setupOdcMetrics } from '@/utility/odc-metrics';
import { IStore } from '@/utility/redux/store';
import { getTranslations } from '@/utility/server/getTranslations';
import {
  decodeJWT,
  getAuthToken,
  isNewImpersonatedLogin,
  reset2FASessionCookie,
  set2FASessionCookie,
  setSessionCookie,
  setSessionFromURLToken,
} from '@/utility/session';

const isProd = process.env.NODE_ENV === 'production';

getSegment(!isSSR() && !isCI(), process.env.segmentAppKey);
setupDatadogRum();
setupOptimizely(!isSSR() && !isCI(), process.env.deployTarget !== 'production');
setupOdcMetrics(!isSSR(), !isProd || isCI());
const urlTokenData = setSessionFromURLToken();

if (!isSSR()) {
  if (!window.sessionStorage.getItem('riskified-session-id')) {
    const id = uuidv4();
    window.sessionStorage.setItem('riskified-session-id', id);
  }
}

type IntlProviderProps = React.ComponentProps<typeof IntlProvider>;

interface IRenterApp {
  reduxStore: IStore;
  locale: IntlProviderProps['locale'];
  messages: IntlProviderProps['messages'];
}

type TRenterApp = React.FC<AppProps<Record<string, any>> & IRenterApp> & {
  getInitialProps?: (context: AppContext) => Promise<AppInitialProps>;
};

const RenterApp: TRenterApp = ({ Component, pageProps, reduxStore, locale, messages }) => {
  const state = reduxStore.getState();
  const isAuthenticated = selectAuthenticated(state);
  const intl = useMemo(() => setupIntl(locale, messages), [locale, messages]);
  const router = useRouter();
  const windowSize = useWindowSize();
  const siftKey = process.env.siftKey;

  // On app render, if we have campground info from redux (via SSR)
  // we will set global campground page info from known source
  setODNEventProperties(state.campgroundListing.data);
  setODNEventProperties(state.listing.data?.campground);

  const isOutdoorsStays = !!state.booking.details?.data?.sibling_booking_group_id;
  const isTripDetailsPage = router?.pathname?.startsWith('/trips/[slug]');

  const pathWithoutQuery = router.asPath.split('?')[0];

  const appHeight = () => {
    !isSSR() &&
      document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
  };

  // Track page changes but not when user interactions lead to query param changes.
  useEffect(() => {
    // we may override the page type for some localities to determine the type of campground page being viewed
    let pageType = pageProps?.metadata?.page_type;
    const campgroundPageType = getCampgroundPageType(pageProps?.metadata);
    if (campgroundPageType) pageType = campgroundPageType;
    const storedPageMetaData = storePageMetaData(campgroundPageType);

    trackPageView({
      window,
      storedPageMetaData,
      pageType,
      projectSection: EProjectSection.MARKETPLACE,
    });
  }, [pathWithoutQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  // Help Menu (GTM) is no longer opening qualtrics survey.
  // Instead we trigger 'Feedback CTA Selected', that will open Sprig survey.
  useEffect(() => {
    const giveUsFeedback = () => {
      trackFeedbackCTASelectedEvent({
        pageCategory: parsePageMetaData(window.sessionStorage).pageCategory,
        source: EFeedbackEventSource.HELP_MENU,
      });
    };

    document.addEventListener('help-menu:give-us-feedback', giveUsFeedback);
    return () => document.removeEventListener('help-menu:give-us-feedback', giveUsFeedback);
  }, [isOutdoorsStays, isTripDetailsPage]);

  const queryParams = useQueryParams();

  useEffect(() => {
    appHeight();
  }, [windowSize]);

  useEffect(() => {
    reduxStore.dispatch(setQueryParam(queryParams));

    const handleRouteChange = (url: string) => {
      // @ts-expect-error fixable: unchecked index access
      const newParams = querystring.parse(url.split('?')[1]);
      reduxStore.dispatch(setQueryParam(newParams));
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [reduxStore, queryParams, router.events]);

  useEffect(() => {
    (async () => {
      const anonId = await getODAnonymousId();
      setOptimizelyUser(anonId, { locale });
    })();
  }, [locale]);

  // This effect is only intended to be run on first mount.
  //   1. Load user on page refresh if there is an auth token.
  //   2. If the user logged in via token, update token with user values.
  // If run again, will cause unwanted loading states because useWindowSize re-renders the app.
  useEffect(() => {
    (async () => {
      const queryToken = queryParams.token as string;
      const show2FAScreen = queryParams.show_2fa_screen as string;
      const twoFASessionId = queryParams.session_id_2fa as string;

      // If the user is logged in via token (social auth) and 2fa is required,
      // create the 2fa session cookie and start the 2fa flow
      if (show2FAScreen && twoFASessionId) {
        const next = queryParams.next as string;
        const socialAuthData = {
          session_id_2fa: twoFASessionId,
          show_2fa_screen: Boolean(queryParams.show_2fa_screen),
          preferred_2fa_method: queryParams.preferred_2fa_method as string,
          email: queryParams.email as string,
          phone: queryParams.phone as string,
          next: next,
        };

        set2FASessionCookie(socialAuthData);
        trackSocialLogin(true);
        router.replace('/security', undefined, {
          shallow: true,
        });

        return;
      }

      if (isAuthenticated || getAuthToken()) {
        //load user on store on page refresh
        let shouldIdentifyUser = false;
        if (urlTokenData) {
          shouldIdentifyUser = isNewImpersonatedLogin(urlTokenData);
        }
        const user = await reduxStore.dispatch(fetchUser(false, shouldIdentifyUser));

        // mimic current login response session cookie
        // requires user information
        if (queryToken) {
          const decodedJWT = decodeJWT(queryToken);
          setSessionCookie(decodedJWT?.actor_id || null, {
            first_name: user?.first_name,
            last_name: user?.last_name,
            user_id: user?.id,
            token: queryToken,
          });

          if (!user.impersonated) {
            const socialAuthMethod = getItemFromLocalStorage('social_auth_method');
            // track social auth login
            socialAuthMethod && setItemInLocalStorage('social_auth_success', 'true');
            trackSocialLogin(false);
          }

          // if we still have a 2fa session cookie after creating a regular session
          // clean up the 2fa session cookie
          // mostly a concern for users with multiple accounts (owner/renter)
          reset2FASessionCookie();
        }
      } else {
        // If we dont have auth, tell state that we tried to auth.
        await reduxStore.dispatch(fetchUserSkipped());
      }

      reduxStore.dispatch(loadCookies());
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useAttentiveScript();

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const cookieValue = getCookie('outdoorsy-domain-preference');

    const localeData = Object.values(locales).find(localeEl => localeEl.domain === currentDomain);

    if (localeData && !cookieValue) {
      const countryCode = localeData.country;
      setCookie('outdoorsy-domain-preference', countryCode);
    }
  }, []);

  return (
    <>
      <ExternalFonts
        href="https://use.typekit.net/sek7qeo.css"
        preconnect={['https://use.typekit.net', 'https://p.typekit.net']}
      />

      <Head>
        <link rel="icon" href="/favicon.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta property="fb:app_id" content="604465049688341" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@outdoorsy" />
        <meta name="application-name" content="Outdoorsy" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="apple-mobile-web-capable" content="yes" />
        <meta name="apple-mobile-web-title" content="Outdoorsy" />
        <meta name="apple-mobile-web-status-bar-style" content="black-translucent" />
        <meta name="version" content={process.env.releaseVersion} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link rel="preconnect" href="https://cdn.segment.com" crossOrigin="anonymous" />
        {!!process.env.optimizelyLink && (
          <>
            <link rel="preload" href={process.env.optimizelyLink} as="script" />
            <link rel="preconnect" href="//logx.optimizely.com" />
          </>
        )}
        <meta name="robots" content="max-image-preview:large" />
      </Head>
      <ApolloClient pageProps={pageProps}>
        <SWRConfig
          value={{
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
          }}>
          <Provider store={reduxStore}>
            <ToastProvider>
              <RawIntlProvider value={intl}>
                <BonfireProvider skipFonts>
                  <AppRouteProvider>
                    <Global />
                    <ApplicationLayout pageProps={pageProps}>
                      <TrackUserAlias />
                      <Component {...pageProps} />
                      <TermsAndConditionsModalContainer />
                      <SiftTracker siftKey={siftKey} />
                      {!isCI() && !isDev() && <OneTrust />}
                    </ApplicationLayout>
                  </AppRouteProvider>
                </BonfireProvider>
              </RawIntlProvider>
            </ToastProvider>
          </Provider>
        </SWRConfig>
      </ApolloClient>
      {isProd && !isCI() && <GTMScript pathWithoutQuery={pathWithoutQuery} />}
      <GoogleAds />
      <RiskifiedScript />
    </>
  );
};

RenterApp.getInitialProps = async (appContext: AppContext) => {
  const {
    ctx: { locale: l },
  } = appContext;
  const locale = getLocale(l);

  const [messages, appProps] = await Promise.all([
    getTranslations(locale),
    App.getInitialProps(appContext),
  ]);
  return { ...appProps, locale, messages };
};

export default withReduxStore(RenterApp);
