import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AddressInput from '@/components/switchback/AddressInput/AddressInput';
import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { ESearchFilters } from '@/constants/searchFilters';
import { useRecentSearches } from '@/hooks/useRecentSearches';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';
import { EDeliveryFields, TDeliveryFields } from '@/services/types/core/delivery.types';
import { itemizeAddress } from '@/utility/itemizeAddress';

import { useFilterPillsCtx } from '../../FilterPillsContext/FilterPillsContext';

interface IMovingDeliveryFilterDetailProps {
  deliveryAddressFromQuery: string;
  disableButton?: () => void;
  enableButton?: () => void;
}

export const MovingDeliveryFilterDetail = ({
  deliveryAddressFromQuery,
  disableButton,
  enableButton,
}: IMovingDeliveryFilterDetailProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { setIsAutocompleteOpen } = useFilterPillsCtx();

  const [recentSearches, { addRecentSearch }] = useRecentSearches();

  const searchMeta = useSelector((state: TRootState) => state.search.meta);
  const location = searchMeta ? [searchMeta.lng, searchMeta.lat] : undefined;

  const [deliveryAddress, setDeliveryAddress] = useState(deliveryAddressFromQuery);
  const form = useForm<TDeliveryFields>();

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget?.value;

    if (newValue.length > 2) {
      enableButton?.();
    } else {
      disableButton?.();
    }

    setDeliveryAddress(newValue);
  };

  const handleSelectAddress = (option: IAutocompleteOption) => {
    setDeliveryAddress(option.label);
    const itemizedAddress = itemizeAddress(option.value);
    const { state, country, city, street, zip } = itemizedAddress;

    addRecentSearch(option.label);

    dispatch(
      setFormFilter(
        {
          filters: {
            [ESearchFilters.ADDRESS]: option.label,
            [ESearchFilters.DELIVERY_ADDRESS]: option.label,
            [ESearchFilters.DELIVERY_CENTER]: option.value?.center
              ? JSON.stringify(option.value?.center)
              : '',

            [ESearchFilters.DELIVERY_DETAILS]: encodeURIComponent(
              JSON.stringify({
                country,
                state,
                city,
                street,
                zip,
              }),
            ),
            // if address is out of current bounds, bounds need to be cleared for correct results
            [ESearchFilters.BOUNDS_NE]: undefined,
            [ESearchFilters.BOUNDS_SW]: undefined,
          },
          filterMethod: EFilterMethod.INPUT,
          ignorePersistedFilters: false,
        },
        false,
      ),
    );
  };

  return (
    <div className="mb-4">
      <Text variant={ETextStyleVariant.SmallRegular} className="mb-6">
        <FormattedMessage
          defaultMessage="Let the host deliver the RV to your house, the airport, or somewhere else, so you can hit the road from there."
          id="Gidb5M"
        />
      </Text>

      <AddressInput
        name={EDeliveryFields.LOCATION}
        form={form}
        addressValue={deliveryAddress}
        onChangeAddress={handleChangeAddress}
        onSelectAddress={handleSelectAddress}
        recentSearches={recentSearches}
        placesOption={{
          types: 'country,region,postcode,district,place,locality,neighborhood,address',
          location,
        }}
        label={intl.formatMessage({ defaultMessage: 'Delivery location', id: 'Flx2cd' })}
        popupOptions={{
          disablePortal: false,
          anchorReference: 'anchorEl',
          disableScrollLock: true,
        }}
        popupClassName="p-8 shadow-100 rounded-box !z-[1450] left-6 mt-2"
        skipFocusListener
        onToggleAutocompleteOptions={setIsAutocompleteOpen}
      />
    </div>
  );
};
