import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AddressInput from '@/components/switchback/AddressInput/AddressInput';
import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { ESearchFilters } from '@/constants/searchFilters';
import { useRecentSearches } from '@/hooks/useRecentSearches';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchFormDeliveryFilter } from '@/redux/selectors/search/searchForm';
import {
  getIsDeliveryFilterTriggeredFromAd,
  getNearbyCampgroundsForDelivery,
} from '@/redux/selectors/search/searchResults';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { EDeliveryFields, TDeliveryFields } from '@/services/types/core/delivery.types';
import { ICampgroundLocation } from '@/services/types/search/campgrounds/id';
import { itemizeAddress } from '@/utility/itemizeAddress';
import { getStateName, provinceByCountry, TCountry } from '@/utility/location';

import { useFilterPillsCtx } from '../../FilterPillsContext/FilterPillsContext';
import { CampgroundList } from './CampgroundList';

interface IStationaryDeliveryFilterDetailProps {
  deliveryAddressFromQuery: string;
  disableButton?: () => void;
  enableButton?: () => void;
}

export const getFormattedCampgroundLocation = (location?: ICampgroundLocation) => {
  if (!location) return {};
  const { state, street, city, country, zip } = location;
  const formattedCountry = (country?.toUpperCase() as TCountry) || 'US';

  const stateName = getStateName(state, formattedCountry) ?? state;
  const countryName = provinceByCountry[formattedCountry].name;

  const campgroundAddress = [street, city, `${stateName} ${zip}`, countryName].join(', ');
  return { stateName, countryName, campgroundAddress };
};

export const StationaryDeliveryFilterDetail = ({
  deliveryAddressFromQuery,
  disableButton,
  enableButton,
}: IStationaryDeliveryFilterDetailProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { setIsAutocompleteOpen } = useFilterPillsCtx();
  const {
    [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromQuery,
    [ESearchFilters.GEOIP_ADDRESS]: geoipFromQuery,
  } = useSelector(getQueryParams);

  const { [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromStore } =
    useSelector(getSearchFormDeliveryFilter);

  const campgroundList = useSelector(getNearbyCampgroundsForDelivery);
  const outdoorsyStaysExperimentEnabled = useExperimentIsEnabled(OptimizelyFlags.OUTDOORSY_STAYS);
  const isValidCampgroundId = campgroundList?.some(
    campground => campground.id === Number(deliveryCampgroundIdFromQuery),
  );

  const searchMeta = useSelector((state: TRootState) => state.search.meta);
  const location = searchMeta ? [searchMeta.lng, searchMeta.lat] : undefined;

  const form = useForm<TDeliveryFields>();

  const [deliveryAddress, setDeliveryAddress] = useState(
    !isValidCampgroundId ? deliveryAddressFromQuery : '',
  );
  const [selectedCampgroundId, setSelectedCampgroundId] = useState<number>();
  const isDeliveryFilterTriggeredFromAd = useSelector(getIsDeliveryFilterTriggeredFromAd);

  const [recentSearches, { addRecentSearch }] = useRecentSearches();

  const handleSelectedCampground = useCallback(
    (id: string) => {
      const campground = campgroundList?.find(data => data.id === Number(id));

      if (!campground || campground.id === selectedCampgroundId) return;

      setSelectedCampgroundId(campground.id);
      const { lng, lat, city, street, zip } = campground.location;
      const { campgroundAddress, stateName, countryName } = getFormattedCampgroundLocation(
        campground.location,
      );

      setDeliveryAddress('');

      dispatch(
        setFormFilter(
          {
            filters: {
              [ESearchFilters.GEOIP_ADDRESS]: geoipFromQuery as string,
              [ESearchFilters.DELIVERY_ADDRESS]: campgroundAddress,
              [ESearchFilters.DELIVERY_CENTER]: JSON.stringify([lng, lat]),
              [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: campground.id,
              [ESearchFilters.DELIVERY_DETAILS]: encodeURIComponent(
                JSON.stringify({
                  country: countryName,
                  state: stateName,
                  city,
                  street,
                  zip,
                }),
              ),
              [ESearchFilters.DELIVERY_CAMPGROUND]: campground.name,
              // if address is out of current bounds, bounds need to be cleared for correct results
              [ESearchFilters.BOUNDS_NE]: undefined,
              [ESearchFilters.BOUNDS_SW]: undefined,
            },
            filterMethod: isDeliveryFilterTriggeredFromAd
              ? EFilterMethod.ODSTAYS_DELIVERY_SEARCH_AD
              : EFilterMethod.RADIO,
            ignorePersistedFilters: false,
            ignoredFiltersUpdate: [ESearchFilters.BOUNDS_NE, ESearchFilters.BOUNDS_SW],
            isOutdoorsyStay: true,
          },
          false,
        ),
      );
      enableButton?.();
    },
    [
      campgroundList,
      selectedCampgroundId,
      dispatch,
      geoipFromQuery,
      isDeliveryFilterTriggeredFromAd,
      enableButton,
    ],
  );

  useEffect(() => {
    if (isValidCampgroundId) {
      setSelectedCampgroundId(
        deliveryCampgroundIdFromStore || Number(deliveryCampgroundIdFromQuery),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryCampgroundIdFromQuery, isValidCampgroundId]);

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget?.value;

    if (newValue.length > 2) {
      enableButton?.();
    } else {
      disableButton?.();
    }

    setDeliveryAddress(newValue);
  };

  const handleSelectAddress = (option: IAutocompleteOption) => {
    setSelectedCampgroundId(undefined);
    setDeliveryAddress(option.label);
    addRecentSearch(option.label);
    const itemizedAddress = itemizeAddress(option.value);
    const { state, country, city, street, zip } = itemizedAddress;

    dispatch(
      setFormFilter(
        {
          filters: {
            [ESearchFilters.GEOIP_ADDRESS]: geoipFromQuery as string,
            [ESearchFilters.ADDRESS]: option.label,
            [ESearchFilters.DELIVERY_ADDRESS]: option.label,
            [ESearchFilters.DELIVERY_CENTER]: option.value?.center
              ? JSON.stringify(option.value?.center)
              : '',

            [ESearchFilters.DELIVERY_DETAILS]: encodeURIComponent(
              JSON.stringify({
                country,
                state,
                city,
                street,
                zip,
              }),
            ),
            // if address is out of current bounds, bounds need to be cleared for correct results
            [ESearchFilters.BOUNDS_NE]: undefined,
            [ESearchFilters.BOUNDS_SW]: undefined,
            [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: undefined,
          },
          filterMethod: isDeliveryFilterTriggeredFromAd
            ? EFilterMethod.ODSTAYS_DELIVERY_SEARCH_AD
            : EFilterMethod.INPUT,
          ignorePersistedFilters: false,
          ignoredFiltersUpdate: [
            ESearchFilters.BOUNDS_NE,
            ESearchFilters.BOUNDS_SW,
            ESearchFilters.ADDRESS,
          ],
        },
        false,
      ),
    );
  };

  return (
    <div className="mb-4">
      <Text variant={ETextStyleVariant.SmallRegular} className="mb-8">
        <FormattedMessage
          defaultMessage="No driving necessary! Let the host deliver and set up the RV at a location below."
          id="Q8tRet"
        />
      </Text>

      {!isDeliveryFilterTriggeredFromAd && (
        <AddressInput
          name={EDeliveryFields.LOCATION}
          form={form}
          addressValue={deliveryAddress}
          onChangeAddress={handleChangeAddress}
          onSelectAddress={handleSelectAddress}
          placesOption={{
            types: 'country,region,postcode,district,place,locality,neighborhood,address',
            location,
          }}
          label={intl.formatMessage({ defaultMessage: 'Set up location', id: 'OcMyLO' })}
          popupOptions={{
            disablePortal: false,
            anchorReference: 'anchorEl',
            disableScrollLock: true,
          }}
          popupClassName="p-8 shadow-100 rounded-box !z-[1450] left-6 mt-2"
          skipFocusListener
          onToggleAutocompleteOptions={setIsAutocompleteOpen}
          recentSearches={recentSearches}
        />
      )}

      {outdoorsyStaysExperimentEnabled && !!campgroundList && (
        <>
          {!isDeliveryFilterTriggeredFromAd && (
            <Text variant={ETextStyleVariant.MediumBold} className="mt-8 mb-4">
              <FormattedMessage defaultMessage="Or select a campground" id="dNJyWc" />
            </Text>
          )}
          <CampgroundList
            campgroundList={campgroundList}
            selectedCampgroundId={selectedCampgroundId}
            handleSelectedCampground={handleSelectedCampground}
          />
        </>
      )}
    </div>
  );
};
