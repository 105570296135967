import { createSelector } from 'reselect';

import { defaultLocale, locales, TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { getCurrency } from '@/redux/selectors/currency';
import { getReviewsSummary } from '@/redux/selectors/listing/reviews';
import { getLocale } from '@/redux/selectors/locale';
import {
  ICrumb,
  IData,
  IData as IListing,
  IImage,
  ILocation,
  TEpisodicCoverage,
} from '@/services/types/search/rentals/id';
import { formatCurrency } from '@/utility/currency';
import formatImageURL from '@/utility/formatImageURL';
import { isSSR } from '@/utility/isSSR';
import { getStayTypeDisplay, getVehicleTypeDisplay, isVehicleDrivable } from '@/utility/rentals';
import { stripHTML } from '@/utility/stripHtml';

import { getFromAndTo } from '../queryParams';

type TListingData = TRootState['listing']['data'];

type TListingName = Pick<
  IListing,
  'display_vehicle_type' | 'vehicle_make' | 'vehicle_model' | 'vehicle_year'
>;

export enum PriceType {
  DAY = 'day',
  NIGHT = 'night',
}

export const getVehicleListingTitle = ({
  display_vehicle_type,
  vehicle_make,
  vehicle_model,
  vehicle_year,
}: TListingName) =>
  [vehicle_year, vehicle_make, vehicle_model].filter(Boolean).join(' ') +
  ' ' +
  getVehicleTypeDisplay(display_vehicle_type);

export const getListingData = createSelector<TRootState, TListingData, IData | null>(
  state => state.listing.data,
  data => data,
);
export const getListingDeliveryRadiusInMiles = createSelector<
  TRootState,
  TListingData,
  number | null
>(
  state => state.listing.data,
  data => (data?.delivery ? data?.delivery_radius_miles : null),
);

export const getListingPresentmentCurrency = createSelector<TRootState, TListingData, TCurrency>(
  state => state.listing.data,
  data => data?.presentment_currency || defaultLocale.base_currency,
);

export const getListingSettlementCurrency = createSelector<TRootState, TListingData, TCurrency>(
  state => state.listing.data,
  data => data?.settlement_currency || data?.presentment_currency || defaultLocale.base_currency,
);

export const getListingPriceType = createSelector<TRootState, TListingData, PriceType>(
  state => state.listing.data,
  data => (data?.active_options.use_day_pricing ? PriceType.DAY : PriceType.NIGHT),
);

export const getListingId = createSelector<TRootState, TListingData, number | undefined>(
  state => state.listing.data,
  data => data?.id,
);

const getListingPrice = createSelector<
  TRootState,
  number | undefined,
  ReturnType<typeof getCurrency>,
  ReturnType<typeof formatCurrency>
>(
  state => state.listing.data?.price_per_day,
  getCurrency,
  (price, currency) => formatCurrency({ priceInCents: price, currency, digits: 2 }),
);

export const getListingSocialImage = createSelector<
  TRootState,
  IImage[] | undefined,
  ReturnType<typeof formatImageURL> | undefined
>(
  state => state.listing.data?.images,
  images => {
    const image = images?.find(image => image.primary)?.url ?? images?.[0]?.url;
    return image ? formatImageURL(image, 'wide1200', 'fill') : undefined;
  },
);

export const getListingTitle = createSelector<TRootState, TListingData, string>(
  state => state.listing.data,
  data => {
    if (!data) {
      return '';
    }

    const {
      location: { city, state },
      display_vehicle_type,
      vehicle_make,
      vehicle_model,
      vehicle_year,
      name: listingName,
    } = data;

    let name: string;

    if (!data.stay) {
      name = getVehicleListingTitle({
        display_vehicle_type,
        vehicle_make,
        vehicle_model,
        vehicle_year,
      });
    } else {
      name =
        `${listingName ? `${listingName} - ` : ''}` +
        getStayTypeDisplay(data.stay.display_stay_type);
    }

    // FIXME: should title be translated?
    return `${name} Rental in ${city}, ${state}`;
  },
);

export const getListingDescription = createSelector<
  TRootState,
  TListingData,
  ReturnType<typeof getListingPrice>,
  string
>(
  state => state.listing.data,
  getListingPrice,
  (data, price) => {
    if (!data) {
      return '';
    }

    const {
      images,
      location: { city, state },
      display_vehicle_type,
      vehicle_make,
      vehicle_model,
      vehicle_year,
      active_options: { use_day_pricing },
    } = data;

    let name: string;
    if (!data.stay) {
      name = getVehicleListingTitle({
        display_vehicle_type,
        vehicle_make,
        vehicle_model,
        vehicle_year,
      });
    } else {
      name = getStayTypeDisplay(data.stay.display_stay_type);
    }

    return `See ${
      images.length
    } photos of this ${name} in ${city}, ${state} for rent now at ${price}/${
      use_day_pricing ? 'day' : 'night'
    }`;
  },
);

const getListingCanonicalSlug = createSelector<TRootState, TListingData, string | undefined>(
  state => state.listing.data,
  data => data?.slug,
);

export const getListingAlternatePaths = createSelector<
  TRootState,
  ReturnType<typeof getListingCanonicalSlug>,
  { locale: string; href: string }[]
>(getListingCanonicalSlug, slug =>
  Object.values(locales).map(({ locale, domain }) => ({
    locale,
    href: `https://${domain}${slug}`,
  })),
);

export const getListingCanonicalURL = createSelector<
  TRootState,
  ReturnType<typeof getListingCanonicalSlug>,
  ReturnType<typeof getLocale>,
  string
>(getListingCanonicalSlug, getLocale, (slug, locale) => `https://${locale.domain}${slug}`);

export const getListingMetaProperties = createSelector<
  TRootState,
  ReturnType<typeof getListingCanonicalURL>,
  ReturnType<typeof getListingTitle>,
  ReturnType<typeof getListingDescription>,
  ReturnType<typeof getListingSocialImage>,
  ReturnType<typeof getListingPrice>,
  ReturnType<typeof getCurrency>,
  { property: string; content: string | undefined }[]
>(
  getListingCanonicalURL,
  getListingTitle,
  getListingDescription,
  getListingSocialImage,
  getListingPrice,
  getCurrency,
  (url, title, description, image, price, currency) => [
    { property: 'og:url', content: url },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:type', content: 'product' },
    { property: 'product:price:amount', content: price },
    { property: 'product:price:currency', content: currency },
  ],
);

export const getListingStructuredProduct = createSelector<
  TRootState,
  TListingData,
  ReturnType<typeof getListingSocialImage>,
  ReturnType<typeof getListingCanonicalSlug>,
  ReturnType<typeof getReviewsSummary>,
  string
>(
  state => state.listing.data,
  getListingSocialImage,
  getListingCanonicalSlug,
  getReviewsSummary,
  (data, image, canonicalSlug, reviews) => {
    if (!data) return '{}';

    const {
      name,
      id,
      description,
      vehicle_make,
      vehicle_model,
      vehicle_length,
      presentment_currency,
      price_per_day,
      children_count,
      group_reviews_num,
      group_score,
      reviews_num,
      score,
      locale: { length_unit },
    } = data;

    const review = reviews?.reviews?.[0];
    const ratingValue = children_count > 0 ? group_score : score;
    const ratingCount = children_count > 0 ? group_reviews_num : reviews_num;

    const response = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      additionalType: 'http://www.productontology.org/id/recreational_vehicle',
      image,
      name,
      sku: id.toString(),
      description,
      brand: {
        '@type': 'Brand',
        name: vehicle_make,
      },
      model: vehicle_model,
      depth: `${vehicle_length} ${length_unit}`,
      offers: [
        {
          priceCurrency: presentment_currency,
          price: price_per_day / 100,
          businessFunction: 'http://purl.org/goodrelations/v1#LeaseOut',
          url: canonicalSlug,
          availability: 'http://schema.org/InStock',
          availableDeliveryMethod: 'http://purl.org/goodrelations/v1#DeliveryModePickUp',
        },
      ],
      manufacturer: {
        '@type': 'Organization',
        name: vehicle_make,
      },
      aggregateRating:
        ratingValue !== 0 && ratingCount !== 0
          ? {
              '@type': 'AggregateRating',
              ratingValue: ratingValue,
              ratingCount: ratingCount,
            }
          : undefined,
      review: review
        ? {
            '@type': 'review',
            author: {
              '@type': 'Person',
              name: review.name,
            },
            reviewBody: stripHTML(review.review),
            reviewRating: {
              '@type': 'Rating',
              ratingValue: review.ratingNumber,
            },
          }
        : undefined,
    };

    return JSON.stringify(response);
  },
);

export const getListingStructuredAccommodation = createSelector<
  TRootState,
  TListingData,
  ReturnType<typeof getListingSocialImage>,
  string
>(
  state => state.listing.data,
  getListingSocialImage,
  (data, image) => {
    if (!data) {
      return '';
    }

    const {
      name,
      description,
      vehicle_year,
      location: { city, state, lat, lng },
    } = data;

    const response = {
      '@context': 'https://schema.org',
      '@type': 'Accommodation',
      additionalType: 'http://www.productontology.org/id/recreational_vehicle',
      image,
      name,
      description,
      petsAllowed: ['stay', 'campground'].includes(data.rental_category)
        ? String(false)
        : String(data.features?.pet_friendly === true),
      yearBuilt: vehicle_year,
      address: {
        addressLocality: city,
        addressRegion: state,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: lat,
        longitude: lng,
      },
    };

    return JSON.stringify(response);
  },
);

export const getListingStructuredBreadbrumbs = createSelector<
  TRootState,
  ICrumb[] | undefined,
  string
>(
  state => state.listing.data?.breadcrumb?.crumbs,
  breadcrumbs => {
    if (!breadcrumbs) return '{}';

    const response = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListOrder: 'Ascending',
      itemListElement: breadcrumbs.map(({ title, url }, index) => ({
        '@type': 'ListItem',
        name: title,
        position: index + 1,
        url,
        item: {
          '@type': 'Thing',
          id: url,
        },
      })),
    };

    return JSON.stringify(response);
  },
);

const getMinimumRestriction = (
  activeMinimumDays = 0,
  requestDuration: number,
  bookingDuration: number,
) => {
  let hasRestriction: boolean;
  if (requestDuration && requestDuration < bookingDuration) {
    hasRestriction = true;
  } else {
    const duration = requestDuration || bookingDuration;
    hasRestriction = !duration || duration < activeMinimumDays;
  }

  if (hasRestriction) return bookingDuration || activeMinimumDays;
  return 0;
};

const getBookingDuration = (state: TRootState) => state.quote.data?.duration as number;
const getRequestDuration = (state: TRootState) => state.quote.data?.requested_duration as number;
const getActiveMinimumDays = (state: TRootState) =>
  state.listing.data?.active_options?.minimum_days;

export const getListingMinimumDays = createSelector<
  TRootState,
  ReturnType<typeof getActiveMinimumDays>,
  ReturnType<typeof getRequestDuration>,
  ReturnType<typeof getBookingDuration>,
  ReturnType<typeof getMinimumRestriction>
>(getActiveMinimumDays, getRequestDuration, getBookingDuration, getMinimumRestriction);

export const getIsListingLoading = createSelector<
  TRootState,
  TRootState['listing'],
  boolean | undefined
>(
  state => state.listing,
  listing => listing.isLoading,
);

export const getIsListingPublished = createSelector<TRootState, TListingData, boolean | undefined>(
  state => state.listing.data,
  data => data?.published,
);

export const getListingLocation = (state: TRootState) => {
  return state.listing.data?.location;
};

export interface IListingEventData {
  event: string;
  product_id?: number;
  rental_category: 'rv' | 'stay' | 'campground';
  sku?: number;
  category?: string;
  name?: string;
  sleeps?: number;
  rental_id?: number;
  price: number;
  url?: string;
  image_url?: string;
  from?: string;
  to?: string;
  instant_book?: boolean;
  episodicCoverage?: string;
  odnID?: string;
  campgroundAddress?: string;
  campgroundCheckInTime?: string;
  campgroundCheckOutTime?: string;
  campgroundName?: string;
  displaySiteCategoryType?: string;
  displaySiteType?: string;
  siteCategoryName?: string;
  siteCategoryType?: string;
  stayType?: string;
  isOutdoorsyDestination?: boolean;
  isCampground?: boolean;
  hasWeatherInsurance?: boolean;
  location?: ILocation;
}

export const getListingEventData = createSelector<
  TRootState,
  TListingData,
  ReturnType<typeof getFromAndTo>,
  ReturnType<typeof getListingSocialImage>,
  IListingEventData
>(
  state => state.listing.data,
  getFromAndTo,
  getListingSocialImage,
  (data, selectedDates, image) => {
    return {
      event: 'Viewed Product',
      rental_category: data?.rental_category || 'rv',
      product_id: data?.id,
      sku: data?.id,
      category: data?.type,
      name: data?.name,
      sleeps: data?.campsite_category?.max_occupancy || data?.stay?.sleeps || data?.sleeps,
      rental_id: data?.id,
      price: (data?.price_per_day || 0) / 100,
      url: !isSSR() ? window.location.href : undefined,
      image_url: image,
      from: selectedDates.from,
      to: selectedDates.to,
      instant_book: data?.instant_book,
      episodicCoverage: getEpisodicCoverage(data),
      isCampground: data?.rental_category === 'campground',
      ...(data?.rental_category === 'campground' && {
        odnID: data.campground.odn_id,
        campgroundAddress: `${data?.campground?.location.street}, ${data?.campground?.location.city}, ${data?.campground?.location.state}, ${data?.campground?.location.zip}`,
        campgroundCheckInTime: data?.campground?.check_in_time,
        campgroundCheckOutTime: data?.campground?.check_out_time,
        campgroundName: data?.campground?.name,
        displaySiteCategoryType: data?.campsite_category?.display_category_type,
        displaySiteType: data?.campsite_category?.display_site_type,
        siteCategoryName: data?.campsite_category?.name,
        siteCategoryType: data?.campsite_category?.category_type,
        stayType: data?.campsite_category?.site_type,
        isOutdoorsyDestination: data?.campground?.is_odn,
      }),
      location: data?.location,
    };
  },
);

export const getAverageReviewsRatings = (state: TRootState) => state.listing.data?.average_reviews;

export const getListingScore = (state: TRootState) => state.listing.data?.score;

export const getListingReviewsNum = (state: TRootState) => state.listing.data?.reviews_num;

function isRegisteredInUSState(data: TListingData, lowercaseState: string) {
  const state = lowercaseState.toUpperCase();
  const homeState = data?.location?.state;
  if (homeState && homeState.toUpperCase() == state) {
    return true;
  }
  const licensePlateState = data?.vehicle_license_plate_state;
  if (licensePlateState && licensePlateState.toUpperCase() == state) {
    return true;
  }
  return false;
}

function isSpecialInsuranceCase(data: TListingData) {
  return !!data?.type && isVehicleDrivable(data?.type) && isRegisteredInUSState(data, 'NY');
}

export function getEpisodicCoverage(data: TListingData): TEpisodicCoverage {
  if (!data || data.dealer) {
    return TEpisodicCoverage.missing_data;
  }
  if (!data.insurance_eligible) {
    return TEpisodicCoverage.none;
  }
  if (isSpecialInsuranceCase(data)) {
    return TEpisodicCoverage.ny_stationary;
  }
  return TEpisodicCoverage.full;
}

export const getListingCampsiteCategoryType = (state: TRootState) =>
  state.listing.data?.campsite_category?.category_type;

export const getListingCanChargeSecurityDeposit = (state: TRootState) =>
  state.listing.data?.can_charge_security_deposit;
