import {
  TCurrency,
  TDistanceUnit,
  TLengthUnit,
  TLiquidUnit,
  TLocale,
  TWeightUnit,
} from '@/config/locales';
import { ECancellationPolicies } from '@/constants/houseRules';
import { UNITS } from '@/constants/units';
import { IQuestionAnswers } from '@/services/types/faq/faqs';
import { ICoordinates } from '@/utility/commonDataTypes';

import { ICampgroundData } from '../campgrounds/id';

export enum ERentalCategory {
  STAY = 'stay',
  RV = 'rv',
  CAMPGROUND = 'campground',
}

export interface ISearchRentalsJson {
  data?: IData[];
  meta?: IMeta;
}

export interface INearbyCampgroundsForDelivery {
  data: ICampgroundData[];
  meta: IMeta;
}
interface ISearchRentalSuggestions {
  nearby_deliverable_rvs?: ISearchRentalsJson;
  random_stays?: ISearchRentalsJson;
  nearby_rvs?: ISearchRentalsJson;
  nearby_campgrounds_for_delivery?: INearbyCampgroundsForDelivery;
}
export interface ISearchRentals extends ISearchRentalsJson {
  suggestions?: ISearchRentalSuggestions;
  status?: string;
  code?: string;
  detail?: string;
}

export interface ISearchRentalsById {
  data: IData;
  status?: string;
  code?: string;
  detail?: string;
}

export type TVehicleType =
  | 'camper-van'
  | 'a'
  | 'b'
  | 'c'
  | 'fifth-wheel'
  | 'folding-trailer'
  | 'other'
  | 'toy-hauler'
  | 'tow-vehicle'
  | 'trailer'
  | 'truck-camper';
export type TStayType =
  | 'stays'
  | 'cabin'
  | 'camper-rv'
  | 'canvas-tent'
  | 'camping-tent'
  | 'tiny-home'
  | 'yurt'
  | 'hut'
  | 'tipi'
  | 'barn'
  | 'treehouse'
  | 'dome'
  | 'a-frame'
  | 'ranch'
  | 'other'
  | 'house'
  | 'cottage';

export type TStayCategories = 'unique-stays' | 'cabins' | 'campers' | 'tents';

export type TVehicleStyle = 'drivable' | 'towable';

export type TStayPropertyType = 'private-property' | 'campground';

type TFuelType = 'gas' | 'gasoline' | 'diesel' | 'electric' | '';
type TTransmission = 'automatic' | 'manual' | '';
type TConnectorType = '4pin' | '7pin';
export enum TEpisodicCoverage {
  missing_data = '',
  full = 'full',
  ny_stationary = 'ny_stationary',
  none = 'none',
}

interface IPriceHistogram {
  data: number[];
  max_value: number;
}

export interface IVehicleType {
  type: TVehicleType;
  label: string;
  style: TVehicleStyle;
}

interface IMetaRequest {
  from?: string;
  to?: string;
}
export interface IMeta {
  radius: number;
  lat: number;
  lng: number;
  city: string;
  county?: string;
  state: string;
  country: string;
  country_code: string;
  country_name: string;
  total: number;
  start_position: number;
  stop_position: number;
  price_max?: number;
  price_min?: number;
  price_average?: number;
  price_histogram?: IPriceHistogram;
  request?: IMetaRequest;
  vehicle_types?: IVehicleType[];
  locale: string;
  experiment?: string;
  experiments?: Record<string, string>;
  experiments_data?: Record<string, Record<string, unknown>>;
  suggested?: boolean;
  is_blended?: boolean;
}

interface IOwnerSocial {
  count: number;
  link: string;
  rating: number;
  site: string;
}

export interface IOwner {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
  logo_url: string;
  mast_image_url: string;
  dealer: boolean;
  pro: boolean;
  guest: boolean;
  slug: string;
  social: IOwnerSocial[];
  cancel_policy: string;
  hidden: boolean;
  description: string;
  experience: string;
  years_owned: number;
  years_rving: number;
  phone: string;
  disable_phone: boolean;
  score: number;
  reviews_count: number;
  owner_score: number;
  owner_reviews_count: number;
  time_to_first_action: number;
  response_percent: number;
  average_response_time: number;
  median_response_time: number;
  total_bookings: number;
  accepted_bookings: number;
  accept_percent: number;
  search_metrics_start_date: string;
  business_name: string;
  business_description: string;
  business_phone: string;
  business_website: string;
  locale_language: TLocale;
  locale_base_currency: TCurrency;
  created: string;
  bank_country?: string;
  country?: string;
}

export interface IFeatures {
  air_conditioner: boolean;
  audio_inputs: boolean;
  awning: boolean;
  backup_camera: boolean;
  beds_full: number;
  beds_king: number;
  beds_queen: number;
  beds_twin: number;
  beds_dinette_conversion: number;
  beds_bunk: number;
  beds_fold_out_sofa: number;
  beds_other: number;
  bike_rack: boolean;
  brake_controller: boolean;
  festival_friendly: boolean;
  cd_player: boolean;
  ceiling_fan: boolean;
  connector_type: TConnectorType;
  dining_table: boolean;
  extra_storage: boolean;
  fuel_tank: number;
  fuel_type: TFuelType;
  generator: boolean;
  gray_tank: number;
  handicap_accessible: boolean;
  heater: boolean;
  hitch_weight: number;
  hot_water_tank: boolean;
  inside_shower: boolean;
  international_travel_allowed: boolean;
  inverter: boolean;
  kitchen_sink: boolean;
  leveling_jacks: boolean;
  microwave: boolean;
  minimum_age: number;
  mpg: number;
  one_way_rentals: boolean;
  outside_shower: boolean;
  oven: boolean;
  pet_friendly: boolean;
  propane_tank: number;
  provides_receivers: boolean;
  radio: boolean;
  refrigerator: boolean;
  satellite: boolean;
  sewage_tank: number;
  skylight: boolean;
  slide_outs: number;
  smoking_allowed: boolean;
  solar: boolean;
  stove: boolean;
  tailgate_friendly: boolean;
  toilet: boolean;
  tow_hitch: boolean;
  trailer_weight: number;
  transmission: TTransmission;
  tv_dvd: boolean;
  washer_dryer: boolean;
  water_tank: number;
  wifi: boolean;
}

export enum EImageTags {
  ALL = 'all',
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
  FLOORPLAN = 'floorplan',
  VIDEO = 'video',
  OTHER = 'other',
  NONE = '',
  LODGING_SITE = 'lodging_site',
  TENT_SITE = 'tent_site',
  SITE_MAP = 'site_map',
  CAMPGROUND = 'campground',
  RV_SITE = 'rv_site',
}

export interface IImageCategory {
  name: string;
  slug: EImageTags;
}

export interface IOdnMedia {
  url: string;
  is360Tour: boolean;
  isVideo: boolean;
}

export interface IImage {
  best: boolean;
  description?: string;
  category?: IImageCategory;
  height?: number;
  id: number;
  position: number;
  primary: boolean;
  rental_id: number;
  skip_enhance: boolean;
  tags?: EImageTags;
  url: string;
  video: boolean;
  width?: number;
  alt?: string;
  odnMedia?: IOdnMedia;
}

interface IAddOn {
  id: number;
  rental_id: number;
  name: string;
  description: string;
  required: boolean;
  daily: boolean;
  count: number;
}

export interface ILocation {
  street?: string;
  street_etc?: string;
  city: string;
  state: string;
  county: string;
  country: string;
  zip: string;
  lat: number;
  lng: number;
}

interface IHome {
  city: string;
  country: string;
  lat: number;
  lng: number;
  state: string;
}

interface IVehicle {
  length: number;
  make: string;
  model: string;
  unit_of_measure: string;
  year: number;
  license_plate_state: string;
}

interface IDateRange {
  gte: string;
  lte: string;
}

interface IBookingPrice {
  rental_id: number;
  date_range: IDateRange;
  booking_start: string;
  booking_end: string;
  minimum_booking_days: number;
  price: number;
  max_price: number;
  range: string;
}

export interface IActiveOptions {
  date: string;
  day_price: number;
  max_override_price: number;
  week_price: number;
  weekly_rate_per_day: number;
  weekly_discount_percentage: number;
  month_price: number;
  monthly_rate_per_day: number;
  monthly_discount_percentage: number;
  cancel_policy: string;
  minimum_days: number;
  use_day_pricing: boolean;
  use_tax_inclusive_pricing: boolean;
  instant_book: boolean;
}

export interface IUsageTier {
  id: number;
  usage_based_item_id: number;
  price: number;
  maximum: number;
  minimum_fee: number;
}

export interface IUsageItem {
  id: number;
  owner_id: number;
  name: string;
  unit: string;
  fee_type: string;
  included: number;
  included_period: string;
  unlimited: boolean;
  archived: boolean;
  tax_rate_id: number;
  single_tier: boolean;
  tiers?: IUsageTier[];
}

interface IInsurancePlan {
  id: number;
  label: string;
  renter_headline: string;
  renter_body: string;
}

export interface ICrumb {
  title: string;
  url: string;
}

interface IBreadcrumbMetadata {
  search_hits: number;
}

interface IBreadcrumb {
  id: string;
  locale: string;
  country_alpha: string;
  location: ICoordinates;
  localities: string[];
  metadata: IBreadcrumbMetadata;
  crumbs: ICrumb[];
}

interface IWeekdayUnavailable {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export interface ITag {
  tag: string;
  slug: string;
  count: number;
}

interface ILocalizedContent {
  id: number;
  locale: string;
  field: string;
  text: string;
}

interface IPreferredPrimaryImage {
  id: number;
  rental_id: number;
  primary: boolean;
  position: number;
  tags: string;
  description: string;
  skip_enhance: boolean;
  video: boolean;
  url: string;
  best: boolean;
}

interface INearbyContentData {
  city: string;
  count: number;
  display: string;
  path: string;
}

interface INearbyContent {
  data: INearbyContentData[];
  title: string;
  type: string;
}

interface ITaxRate {
  id: number;
  rate: number;
}

interface IBooking {
  gte: Date;
  lte: Date;
}

export interface IAmenityCategory {
  id: string;
  name: string;
}

enum ETowableFeature {
  GROSS_VEHICLE_WEIGHT = 'gross_vehicle_weight',
  HITCH_WEIGHT = 'hitch_weight',
  CONNECTOR_TYPE = 'connector_type',
  BRAKE_CONTROLLER = 'brake_controller',
  PROVIDES_RECEIVERS = 'provides_receivers',
}

interface ITowableFeature {
  feature: ETowableFeature;
  name: string;
}

interface ICatalog {
  towable_features: ITowableFeature[];
  processed_amenities: IProcessedAmenities;
  amenities_categories: IAmenityCategory[];
}

interface ITerrainFeature {
  feature: string;
  name: string;
}

interface IActivityFeature {
  feature: string;
  name: string;
}

interface IProcessedAmenity {
  id: string;
  available: boolean;
  category: string;
  name: string;
  name_available?: string;
  name_unavailable?: string;
}

export interface IProcessedAmenities {
  [key: string]: IProcessedAmenity[];
}

interface IStayCatalog {
  terrain_features: ITerrainFeature[];
  activity_features: IActivityFeature[];
  processed_amenities: IProcessedAmenities;
  amenities_categories: IAmenityCategory[];
}

export interface IStayFeatures {
  pet_friendly?: boolean;
  ada_accessible?: boolean;
  air_conditioning?: boolean;
  bath_towels?: boolean;
  bbq_grill?: boolean;
  beach?: boolean;
  biking?: boolean;
  blankets?: boolean;
  boating?: boolean;
  camping?: boolean;
  carbon_monoxide_detector?: boolean;
  ceiling_fan?: boolean;
  climbing?: boolean;
  coffee?: boolean;
  coffee_maker?: boolean;
  compost_toilet?: boolean;
  cooking_basics?: boolean;
  countryside?: boolean;
  desert?: boolean;
  dining_table?: boolean;
  dishes_and_utensils?: boolean;
  dryer?: boolean;
  farm?: boolean;
  fire_extinguisher?: boolean;
  fire_pit?: boolean;
  first_aid_kit?: boolean;
  fishing?: boolean;
  flush_toilet?: boolean;
  fresh_linens?: boolean;
  heating?: boolean;
  hiking?: boolean;
  horseback_riding?: boolean;
  hot_spring?: boolean;
  hot_tub?: boolean;
  hot_water?: boolean;
  indoor_fireplace?: boolean;
  indoor_shower?: boolean;
  kayaking?: boolean;
  kitchen_sink?: boolean;
  lake?: boolean;
  microwave?: boolean;
  mountains?: boolean;
  ocean?: boolean;
  outdoor_furniture?: boolean;
  outdoor_shower?: boolean;
  oven?: boolean;
  paddleboarding_watersports?: boolean;
  patio_balcony_deck?: boolean;
  picnic_table?: boolean;
  pillows?: boolean;
  pool?: boolean;
  portable_fan?: boolean;
  potable_water?: boolean;
  pots_and_pans?: boolean;
  ranch?: boolean;
  refrigerator?: boolean;
  shower?: boolean;
  ski_in_ski_out?: boolean;
  skiing_or_snowboarding?: boolean;
  sledding?: boolean;
  smoke_detector?: boolean;
  smoking_allowed?: boolean;
  stove?: boolean;
  surfing?: boolean;
  swimming?: boolean;
  swimming_hole?: boolean;
  toilet_paper?: boolean;
  toiletries?: boolean;
  tv_dvd?: boolean;
  vineyard?: boolean;
  washer?: boolean;
  waterfront?: boolean;
  wheelchair_accessible?: boolean;
  wifi?: boolean;
  woods?: boolean;
  wildlife_watching?: boolean;
  workspace?: boolean;
}

interface IStay {
  rental_id: number;
  sleeps: number;
  beds: number;
  baths: number;
  bedrooms: number;
  features: IStayFeatures;
  type: TStayType;
  display_stay_type: string;
  location_description: string;
  max_parking_spaces: number;
  parking_onsite: boolean;
  check_in_type: string;
}

export enum ECampsiteCategoryType {
  LODGING_SITE = 'lodging_site',
  RV_SITE = 'rv_site',
  TENT_SITE = 'tent_site',
  STATIONARY_CAMPER_SITE = 'stationary_camper_site',
  CAMPSITE = 'campsite',
}

interface ICampsiteImageAsset {
  url: string;
  caption?: string;
}

interface ICampsiteCategoryAmenity {
  name: string;
  quantity: number;
}

export interface ICampgroundCatalog {
  activity_features: IActivityFeature[];
  scenery_features: ITerrainFeature[];
  amenities_categories: IAmenityCategory[];
  processed_amenities: IProcessedAmenities;
}

interface ICampsiteCategory {
  campground_id: number;
  name: string;
  description: string;
  quantity: number;
  max_occupancy: number;
  max_vehicle_length?: number;
  beds: number;
  bedrooms: number;
  bathrooms: number;
  category_type: ECampsiteCategoryType;
  display_category_type?: string;
  display_site_type?: string;
  images: ICampsiteImageAsset[];
  amenities: Record<string, ICampsiteCategoryAmenity>;
  site_type?: string;
}

interface IProcessedAmenity {
  id: string;
  available: boolean;
  category: string;
  name: string;
}

interface ICampsiteCategoryCatalog {
  amenities_categories: IAmenityCategory[];
  processed_amenities: IProcessedAmenities;
}

export interface ISeoContentItem {
  listing_count: number | null;
  title: string;
  url: string;
}

export interface ISeoContent {
  campground?: ISeoContentItem[];
  category?: ISeoContentItem[];
  county?: ISeoContentItem[];
  locality?: ISeoContentItem[];
  park?: ISeoContentItem[];
  links?: ISeoContentItem[];
}

interface IEducationalContent {
  id: string;
  description: string;
  document: string;
  embed_url: string;
  feature: string;
  image: string;
  image_link: string;
  rental_type: string;
  title: string;
}

interface IBaseRentalData {
  id: number;
  name: string;
  type: TVehicleType;
  display_vehicle_type: string;
  description: string;
  summary: string;
  vehicle_class: string;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_gvwr: number;
  vehicle_box_length: number;
  vehicle_length_with_hitch: number;
  vehicle_amps: number;
  vehicle_width: number;
  vehicle_height: number;
  vehicle_dry_weight: number;
  vehicle_license_plate_state: string;
  slug: string;
  vehicle_title: string;
  vehicle_year: number;
  vehicle_length: number;
  availability_set: boolean;
  created: Date;
  updated: Date;
  last_published: Date;
  owner_user_id: number;
  dealer: boolean;
  pro: boolean;
  description_included: string;
  description_recommendations: string;
  description_other: string;
  published: boolean;
  has_been_published: boolean;
  hidden: boolean;
  external: boolean;
  features?: IFeatures;
  coachnet_required: boolean;
  coachnet_ready: boolean;
  instant_book: boolean;
  instant_book_leeway: number;
  sleeps: number;
  seatbelts: number;
  seo_content: ISeoContent;
  sleeps_adults: number;
  sleeps_kids: number;
  rental_price_usage_item_id: number;
  rental_price_usage_item: IUsageItem;
  favorite: boolean;
  delivery: boolean;
  delivery_radius: number;
  delivery_radius_miles: number;
  delivery_radius_unit: UNITS;
  original_url: string;
  primary_image_url: string;
  images: IImage[];
  items: IAddOn[];
  position: number;
  score: number;
  reviews_num: number;
  group_score: number;
  group_reviews_score: number;
  group_reviews_num: number;
  favorite_count: number;
  same_sort_types: number;
  location: ILocation;
  geopoint: ICoordinates;
  group_on_map: boolean;
  current_location_id: number;
  presentment_currency: TCurrency;
  settlement_currency: TCurrency;
  locale: ISearchRentalLocale;
  booking_prices: IBookingPrice[];
  price_per_day: number;
  price_per_week: number;
  price_per_month: number;
  low_price_per_day: number;
  low_price_per_week: number;
  low_price_per_month: number;
  security_deposit: number;
  minimum_deposit: number;
  deposit_percentage: number;
  use_day_pricing: boolean;
  use_tax_inclusive_pricing: boolean;
  cancel_policy: ECancellationPolicies;
  minimum_days: number;
  active_options: IActiveOptions;
  cancel_text: string;
  house_rules: string;
  generator_usage_item_id: number;
  generator_usage_item: IUsageItem;
  mileage_usage_item_id: number;
  mileage_usage_item: IUsageItem;
  delivery_usage_item_id: number;
  delivery_usage_item: IUsageItem;
  insurance_renter_adjustable: boolean;
  insurance_state: string;
  insurance_coverage: string;
  insurance_eligible: boolean;
  insurance_plan: IInsurancePlan;
  custom_insurance_text: string;
  nearby_content?: INearbyContent[];
  tax_rates: ITaxRate[];
  bookings: IBooking[];
  pickup_unavailable: IWeekdayUnavailable;
  dropoff_unavailable: IWeekdayUnavailable;
  tags: ITag[];
  localized_content: ILocalizedContent[];
  parent_id: number;
  children_count: number;
  preferred_primary_image: IPreferredPrimaryImage;
  smart_photo_score: number;
  owner_score: number;
  rental_score: number;
  sort_score: number;
  owner: IOwner;
  breadcrumb: IBreadcrumb;
  can_charge_security_deposit: boolean;
  catalog?: ICatalog;
  education?: IEducationalContent[];
  listing_questions?: IQuestionAnswers[];
  unavailable?: boolean;
  average_ratings?: IAverageRatings;
  average_reviews?: IAverageReviews;
  has_checkout_questions: boolean;
  active_price?: Record<PriceType, number>;
  score_count?: number;
  home?: IHome;
  vehicle?: IVehicle;
  distance?: number;
  prep_fee?: IPrepFee | null;
  blend_logic_source?: string | null;
  stay_catalog?: IStayCatalog;
  stay?: IStay;
  rental_category?: ERentalCategory;
  check_in?: number;
  check_out?: number;
  host_notes?: string;
  misc?: IRentalMiscData | null;
  disallow_movement?: boolean;
}

type IStayRentalData = IBaseRentalData & {
  features: undefined | null;
  catalog: undefined | null;
  stay_catalog: IStayCatalog;
  stay: IStay;
  campground: undefined | null;
  campground_catalog: undefined | null;
  campsite_category: undefined | null;
  campsite_category_catalog: undefined | null;
  rental_category: ERentalCategory.STAY;
};

type ICampgroundRentalData = IBaseRentalData & {
  features: undefined | null;
  catalog: undefined | null;
  stay_catalog: undefined | null;
  stay?: IStay;
  campground: ICampgroundData;
  campground_catalog: ICampgroundCatalog;
  campsite_category: ICampsiteCategory;
  campsite_category_catalog: ICampsiteCategoryCatalog;
  rental_category: ERentalCategory.CAMPGROUND;
};

type IRVRentalData = IBaseRentalData & {
  features: IFeatures;
  catalog: ICatalog;
  stay_catalog: undefined | null;
  stay: undefined | null;
  campground: undefined | null;
  campground_catalog: undefined | null;
  campsite_category: undefined | null;
  campsite_category_catalog: undefined | null;
  rental_category: ERentalCategory.RV;
};

export type IData = IStayRentalData | ICampgroundRentalData | IRVRentalData;

type PriceType = 'day' | 'week' | 'month';
export interface IAverageRatings {
  cleanliness_score: number;
  cleanliness_score_percentage: number;
  communication_score: number;
  communication_score_percentage: number;
  listing_score: number;
  listing_score_percentage: number;
  mechanical_score: number;
  mechanical_score_percentage: number;
  score: number;
  score_percentage: number;
  value_score: number;
  value_score_percentage: number;
}

export interface IAverageReviews {
  owner?: Array<IAverageInfo>;
  rental?: Array<IAverageInfo>;
  score: number;
}

interface IAverageInfo {
  key: string;
  name: string;
  percentage: number;
  score: number;
}

export interface IRentalResponse {
  data?: IData[];
  meta?: IMeta;
}

interface ISearchRentalLocale {
  base_currency: TCurrency;
  distance_unit: TDistanceUnit;
  length_unit: TLengthUnit;
  liquid_unit: TLiquidUnit;
  weight_unit: TWeightUnit;
}

interface IPrepFee {
  description: string;
  amount: number;
}

interface IRentalMiscData {
  travel_to_ny: {
    message: string;
    value: boolean;
  };
}
