import BaseModal from '@material-ui/core/Modal';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import OutdoorsyLogo from '@/assets/images/outdoorsy-logo.svg';
import { CLOSE, HAMBURGER, USER } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';
import { useRouter } from '@/hooks/useRouter';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { getUser } from '@/redux/selectors/auth/user';
import { trackNavClickedEvent } from '@/services/analytics/header';
import { get2FASessionCookie, reset2FASessionCookie } from '@/utility/session';

import SearchHeader from '../SearchHeader/SearchHeader';
import GlobalHeaderLogoOnly from './GlobalHeaderNav/GlobalHeaderLogoOnly';
import GlobalHeaderNavContainer from './GlobalHeaderNav/GlobalHeaderNavContainer';
import GlobalHeaderNavMobileMenu from './GlobalHeaderNav/GlobalHeaderNavMobileMenu';
import GlobalHeaderNavMyAccountAvatar from './GlobalHeaderNav/GlobalHeaderNavMyAccountAvatar';

interface IProps {
  isHomePage?: boolean;
  noSearchNavbar?: boolean;
  isFluidPage?: boolean;
  isFullCanvasPage?: boolean;
  isTransparent?: boolean;
  isSticky?: boolean;
  isSERP?: boolean;
  isHeaderWhite?: boolean;
  isDarkNavigation?: boolean;
  isVacationPackagesPage?: boolean;
}

const GlobalHeader: React.FC<IProps> = ({
  isHomePage,
  noSearchNavbar,
  isFluidPage,
  isFullCanvasPage,
  isTransparent,
  isSticky,
  isSERP,
  isHeaderWhite,
  isDarkNavigation,
  isVacationPackagesPage,
}) => {
  const router = useRouter();
  const user = useSelector(getUser);
  const isAuthenticated = useSelector(selectAuthenticated);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [requires2FA, setRequires2FA] = useState(false);
  const [stuckToTop, setStuckToTop] = useState(false);

  useEffect(() => {
    if (router.pathname === '/security') {
      const twoFASessionCookie = get2FASessionCookie();
      setRequires2FA(Boolean(twoFASessionCookie && twoFASessionCookie.show_2fa_screen));
      return;
    }

    setRequires2FA(false);
  }, [router.pathname]);

  useEffect(() => {
    if (!isSticky) return setStuckToTop(false);

    const updateStuckToTop = () => setStuckToTop(window.scrollY > 0);

    window.addEventListener('scroll', updateStuckToTop, { passive: true });

    updateStuckToTop();

    return () => {
      window.removeEventListener('scroll', updateStuckToTop);
    };
  }, [isSticky]);

  const onClickLogo = () => {
    trackNavClickedEvent({
      clickedLink: 'Outdoorsy',
      isInDropdown: false,
    });

    if (requires2FA) {
      reset2FASessionCookie();
    }
  };

  if (requires2FA) {
    if (isNavOpen) {
      setIsNavOpen(false);
    }

    return <GlobalHeaderLogoOnly onClickLogo={onClickLogo} />;
  }

  return (
    <header
      id="global-header"
      data-testid="global-header"
      style={
        isHomePage || isVacationPackagesPage
          ? { backgroundColor: 'var(--page-header-bg-color)' }
          : {}
      }
      className={cn('z-40', {
        '!bg-transparent': isVacationPackagesPage && !stuckToTop,
        'md:shadow-500': isVacationPackagesPage && stuckToTop,
        'transition-[background-color] duration-700 ease-in-out': isHomePage,
        'z-60': isNavOpen || isMobileSearchOpen,
        'absolute left-0 right-0': isTransparent,
        'transition-colors ease-linear': isTransparent && isSticky && !isVacationPackagesPage,
        'bg-transparent text-white': isTransparent && !stuckToTop,
        'bg-canvas-100 text-gray-900 border-b border-gray-200':
          isTransparent && stuckToTop && !isVacationPackagesPage,
        'relative bg-canvas-100 text-gray-900': !isTransparent,
        'border-b border-gray-200': !isTransparent && !isFullCanvasPage,
        'bg-white border-b-0': isHeaderWhite,
      })}>
      <div
        className={cn({
          'grid grid-cols-main container-main gap-x-default':
            !isFluidPage && !isHomePage && !isVacationPackagesPage,
          'px-6 max-w-xxxxl lg:px-20 mx-auto': isHomePage || isVacationPackagesPage,
        })}>
        <div
          className={cn('flex items-center justify-between py-3 col-content lg:py-7 sm:py-4 h-18', {
            'mx-4 xxxl:mx-16': isFluidPage && !isHomePage && !isVacationPackagesPage,
            'container-main w-full': !isFluidPage && !isHomePage && !isVacationPackagesPage,
            'lg:py-7 sm:py-4': noSearchNavbar,
          })}>
          <Link
            href="/"
            className={cn('mr-4', {
              'hidden xl:block': !noSearchNavbar,
            })}>
            <OutdoorsyLogo
              onClick={onClickLogo}
              className={cn('translate-y-1 transform', {
                'text-white': isTransparent && !isDarkNavigation && !stuckToTop,
                'text-gray-900': !isTransparent || stuckToTop,
              })}
            />
          </Link>

          {!noSearchNavbar && (
            <SearchHeader
              onCloseMobile={() => setIsMobileSearchOpen(false)}
              onOpenMobile={() => setIsMobileSearchOpen(true)}
              isSERP={isSERP}
            />
          )}

          <div className="grow" />

          <button
            className={cn('ml-4 lg:hidden', {
              'rounded-full bg-white w-10 h-10 flex items-center justify-center':
                isHomePage && !isAuthenticated,
            })}
            onClick={() => setIsNavOpen(true)}>
            {(!isAuthenticated && (
              <Icon
                {...(isHomePage
                  ? { name: HAMBURGER, width: 20, height: 20 }
                  : { name: USER, width: 24, height: 24 })}
              />
            )) || (
              <GlobalHeaderNavMyAccountAvatar
                firstName={user?.first_name}
                lastName={user?.last_name}
                avatarUrl={user?.avatar_url}
              />
            )}
          </button>

          <div className="hidden lg:block">
            <GlobalHeaderNavContainer
              isHomePage={isHomePage}
              noSearchNavbar={noSearchNavbar}
              isTransparent={isTransparent && !isDarkNavigation && !stuckToTop}
              isHeaderWhite={isHeaderWhite}
            />
          </div>

          <div className="lg:hidden">
            <BaseModal open={isNavOpen} onClose={() => setIsNavOpen(false)} disablePortal>
              <div
                style={isHomePage ? { backgroundColor: 'var(--page-header-bg-color)' } : {}}
                className={cn('absolute inset-0 z-50 flex flex-col items-start bg-canvas-100', {
                  'transition-[background-color] duration-700 ease-in-out': isHomePage,
                })}>
                <div className="flex items-center justify-end w-full px-4 pt-6 pb-2">
                  <button className="text-gray-800" onClick={() => setIsNavOpen(false)}>
                    <Icon name={CLOSE} width={24} height={24} />
                  </button>
                </div>

                <div className="flex-1 w-full px-4 pb-5 overflow-y-auto">
                  <GlobalHeaderNavMobileMenu userName={user?.first_name} isOwner={user?.owner} />
                </div>
              </div>
            </BaseModal>
          </div>
        </div>
      </div>
    </header>
  );
};

export default GlobalHeader;
