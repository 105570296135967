import { Button, Icon } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

type TFavoriteButton = {
  onClick?: () => void;
  favoriteCount?: number;
  isFavorite?: boolean;
};

export const FavoriteButton = ({ onClick, favoriteCount = 0, isFavorite }: TFavoriteButton) => {
  const intl = useIntl();

  if (!onClick) return;
  return (
    <Button
      className="p-0 border-none rounded-full"
      onClick={onClick}
      onClickCapture={(e: React.MouseEvent) => e.preventDefault()}
      aria-label={
        isFavorite
          ? intl.formatMessage({ defaultMessage: 'Saved', id: 'fsB/4p' })
          : intl.formatMessage({ defaultMessage: 'Click to save', id: 'q+y0s3' })
      }
      label={
        <div
          className={cn('flex items-center w-full h-full px-2 bg-white rounded-full gap-1', {
            'py-1': favoriteCount > 0,
            'py-2': favoriteCount === 0,
          })}>
          {favoriteCount > 0 && (
            <span className="font-normal text-500">
              {favoriteCount > 999
                ? intl.formatMessage({ defaultMessage: '1K+', id: 'dAHibN' })
                : favoriteCount}
            </span>
          )}
          <Icon
            name={isFavorite ? 'General.Heart.Filled' : 'General.Heart.Unfilled'}
            className={cn('!p-0', {
              'text-black': isFavorite,
            })}
          />
        </div>
      }
    />
  );
};
