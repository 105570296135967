import { PopoverProps } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Autocomplete from '@/components/switchback/Autocomplete/Autocomplete';
import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { ALERT_OCTAGON } from '@/components/switchback/Icon/assets';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';
import TextInput from '@/components/switchback/TextInput';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useMapboxQuery from '@/hooks/useMapboxQuery';
import { EDeliveryFields, TDeliveryFields } from '@/services/types/core/delivery.types';

const errorTemplate = (form: UseFormReturn<TDeliveryFields>, type: EDeliveryFields) => {
  const error = form?.formState.errors?.[type];
  if (!error) return null;

  return (
    <div className="w-full mt-2 text-red-800 col-span-2 autoType200">
      <Notice icon={ALERT_OCTAGON} variant={NoticeType.critical}>
        {error.message}
      </Notice>
    </div>
  );
};

interface IAdressInputProps {
  addressValue: string;
  onChangeAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAddress?: (value: IAutocompleteOption) => void;
  onBlurAddress?: () => void;
  name: EDeliveryFields;
  form: UseFormReturn<TDeliveryFields>;
  placesOption?: any;
  inputClassName?: string;
  placeholder?: string;
  popupOptions?: Partial<PopoverProps>;
  label?: string;
  popupClassName?: string;
  skipFocusListener?: boolean;
  onToggleAutocompleteOptions?: (isOpen: boolean) => void;
  required?: boolean;
  recentSearches?: string[];
}

const AddressInput: React.FC<IAdressInputProps> = ({
  addressValue,
  inputClassName,
  onChangeAddress,
  onSelectAddress,
  name,
  form,
  onBlurAddress,
  placesOption,
  placeholder,
  popupOptions,
  label,
  popupClassName,
  onToggleAutocompleteOptions,
  skipFocusListener = false,
  required = true,
  recentSearches = [],
}) => {
  const intl = useIntl();
  const [mapboxQuery, setMapboxQuery] = useState(null);
  const places = useMapboxQuery(mapboxQuery);
  const { isMobile } = useBreakpoint();

  const addressSuggestions =
    addressValue.length >= 3
      ? places.map(p => ({ label: p.place_name, value: p }))
      : recentSearches.map(val => ({ label: val, value: val }));
  const clearErrors = form?.clearErrors;

  //Handle input change
  const handleChangeAddress = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.currentTarget?.value;
      onChangeAddress?.(e);
      if (text === '') {
        clearErrors?.();
      }
      setMapboxQuery(text.length < 3 ? null : { text, ...placesOption });
    },
    [onChangeAddress, clearErrors, placesOption],
  );

  // TODO: test this in different usages of the address input
  useEffect(() => {
    if (addressValue) {
      setMapboxQuery({ text: addressValue, ...placesOption });
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addressInputLabel = intl.formatMessage({
    defaultMessage: 'Delivery address',
    id: 'v3tQCl',
  });

  return (
    <>
      <Autocomplete
        data-testid={`${name}-input-container`}
        inputLabel={addressInputLabel}
        onChangeMobileInput={handleChangeAddress}
        options={addressSuggestions}
        onSelectOption={onSelectAddress}
        popupOptions={popupOptions}
        popupClassName={popupClassName}
        skipFocusListener={skipFocusListener}
        onToggle={onToggleAutocompleteOptions}
        title={
          (isMobile && addressValue.length < 3 && recentSearches.length > 0 && 'Recent Search') ||
          undefined
        }>
        <TextInput
          data-testid={name}
          placeholder={placeholder}
          label={
            label ||
            intl.formatMessage({
              defaultMessage: 'Address',
              id: 'e6Ph5+',
            })
          }
          value={addressValue || ''}
          inputClassName={inputClassName}
          autoComplete="off"
          {...form?.register?.(name, {
            onChange: handleChangeAddress,
            onBlur: onBlurAddress,
            required: {
              value: required,
              message: intl.formatMessage({
                defaultMessage: 'Please enter the delivery address.',
                id: 'CcYJyO',
              }),
            },
          })}
        />
      </Autocomplete>
      {errorTemplate(form, name)}
    </>
  );
};

export default AddressInput;
