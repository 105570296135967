import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useComponentVisible from '@/hooks/useComponentVisible';
import useMapboxQuery from '@/hooks/useMapboxQuery';
import useQueryParams from '@/hooks/useQueryParams';
import { IHeaderSearchFilterDates, IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';
import { applySearchFilter } from '@/redux/modules/search';
import { getSearchResults } from '@/redux/selectors/search/searchResults';
import { ERentalCategory } from '@/services/types/search/rentals/id';
import { limitRecentSearches } from '@/utility/autoCompleteGroupedOptions';
import { formatDateRange } from '@/utility/format-date';
import { stripDiacritics } from '@/utility/stripDiacritics';
import {
  LocationGroupType,
  mapLocationLabelToGroupName,
  setSurfacedLocation,
} from '@/utility/surfacedLocation';

import { QUERYSTRING_DATE_FORMAT } from '../../SearchHeader';
import { EVENT_OPEN_SEARCH_DATES } from '../events';
import DateContent from './DateContent/DateContent';
import DetailsContent from './DetailsContent/DetailsContent';
import GuestsContent from './GuestsContent/GuestsContent';
import LocationSearchContent from './LocationSearchContent';
import LocationSearchInput from './LocationSearchInput';
import { DateTab, GuestsTab, SearchButton } from './SearchTabs';

interface IOption {
  label: string;
  groupName: string;
  value?: any;
  url?: string;
}

interface IGroupOption {
  options: IOption[];
  key: number;
  index: number;
  group: string;
}

enum SEARCH_TAB {
  DATES = 'DATES',
  DETAILS = 'DETAILS',
  LOCATION = 'LOCATION',
}

interface IDesktopNavbarSearchProps {
  handleRecentSearchNavigation?: (url: string) => void;
  onSubmit?: () => void;
  location?: { lat: number; lng: number };
  address?: string;
  guests?: IHeaderSearchFilterGuests;
  dates?: IHeaderSearchFilterDates;
  recentSearches?: IAutocompleteOption[];
  maxRecentSearches: number;
  loading?: boolean;
  onChangeAddress?: (address?: string) => void;
  onChangeDates?: (address?: IHeaderSearchFilterDates) => void;
  onChangeGuests?: (guests?: IHeaderSearchFilterGuests) => void;
  onConfirmAddress?: (address?: any, skipSubmit?: boolean) => void;
  onConfirmDates?: (dates?: IHeaderSearchFilterDates) => void;
  onConfirmGuests?: (guests: IHeaderSearchFilterGuests) => void;
  popularDestinations?: { label: string }[];
  alertStatus?: boolean;
  isPromoSearch?: boolean;
  isCampgroundSearch?: boolean;
  isGuestOccupancyAvailable?: boolean;
  isSerpPage?: boolean;
}

const DesktopNavbarSearch: React.FC<IDesktopNavbarSearchProps> = ({
  onSubmit,
  location,
  address,
  dates,
  guests,
  recentSearches = [],
  maxRecentSearches,
  loading = false,
  onChangeAddress,
  onChangeDates,
  onChangeGuests,
  onConfirmAddress,
  onConfirmDates,
  onConfirmGuests,
  popularDestinations = [],
  alertStatus,
  handleRecentSearchNavigation,
  isPromoSearch,
  isCampgroundSearch,
  isGuestOccupancyAvailable,
  isSerpPage,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const searchResult = useSelector(getSearchResults);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
  const [selectedTab, setSelectedTab] = useState<SEARCH_TAB | null>(null);
  const [addressValue, setAddressValue] = useState(address || '');

  const queryParams = useQueryParams();
  const rentalType = queryParams?.[ESearchFilters.RENTAL_CATEGORY] || ERentalCategory.RV;

  const placesOption = {
    text: addressValue,
    ...(location ? { location: [location.lng, location.lat] } : {}),
    types: 'country,region,postcode,district,place,locality,neighborhood,address',
  };
  const places = useMapboxQuery(addressValue.length < 3 ? null : placesOption);
  const suggestions = places.map(p => ({ label: p.place_name, value: p }));

  const formattedDates = useMemo<string | null>(() => {
    if (!dates?.from || !dates?.to) {
      return null;
    }

    return formatDateRange(dates.from.toString(), dates.to.toString(), false);
  }, [dates]);

  useEffect(() => {
    if (address !== addressValue) {
      setAddressValue(address || '');
    }
  }, [address, addressValue]);

  const suggestionsGroupName = intl.formatMessage({
    defaultMessage: 'Matches',
    id: 'VLIHLz',
    description: 'Global Header > Search',
  });

  const options: IOption[] = [
    ...suggestions.map(option => ({
      ...option,
      groupName: suggestionsGroupName,
    })),
    ...recentSearches.map(option => ({
      ...option,
      groupName: intl.formatMessage({
        defaultMessage: 'Recent Searches',
        id: 'yLl55w',
        description: 'Global Header > Search',
      }),
    })),
    ...popularDestinations.map(option => ({
      ...option,
      groupName: intl.formatMessage({
        defaultMessage: 'Popular Destinations',
        id: 'zWHSAh',
        description: 'Global Header > Search',
      }),
    })),
  ];

  const handleAddressChange = (e: React.ChangeEvent<Record<string, string>>, value: string) => {
    if (!e) return;
    if (selectedTab !== SEARCH_TAB.LOCATION) {
      // Case where text is selected and the user swipes outside the input field.
      handleClickTab(SEARCH_TAB.LOCATION);
    }
    onChangeAddress?.(value);
    setAddressValue(value);
    setSurfacedLocation(LocationGroupType.OTHER);
  };

  const handleAddressSelect = (_e: React.ChangeEvent<Record<string, string>>, value: any) => {
    if (value?.url) {
      handleRecentSearchNavigation?.(value?.url);
      return;
    }
    const groupName = mapLocationLabelToGroupName(value?.groupName);
    setSurfacedLocation(groupName);
    if (!dates && value?.label) {
      setSelectedTab(SEARCH_TAB.DATES);
      onConfirmAddress?.(value, true);
    }
    if (dates && value?.label) {
      onConfirmAddress?.(value);
      setSelectedTab(null);
    }
  };

  const handleChangeDate = (value?: IHeaderSearchFilterDates) => {
    if ((value?.from && value?.to) || isEmpty(value)) {
      onChangeDates?.(value);
    }
  };

  const handleOpen = () => {
    setSelectedTab(SEARCH_TAB.LOCATION);
  };

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions, focused } =
    useAutocomplete({
      inputValue: addressValue,
      options,
      filterOptions: (autocompleteOptions, autocompleteState) => {
        const input = stripDiacritics(autocompleteState.inputValue.trim().toLowerCase());

        return !input
          ? autocompleteOptions
          : autocompleteOptions.filter(autocompleteOption => {
              // Do not filter suggestions, those are already filtered by the API
              if (autocompleteOption.groupName === suggestionsGroupName) {
                return autocompleteOption;
              }

              const candidate = stripDiacritics(
                autocompleteState.getOptionLabel(autocompleteOption).trim().toLowerCase(),
              );

              return candidate.indexOf(input) > -1;
            });
      },
      getOptionLabel: option => option.label,
      groupBy: option => option.groupName,
      onInputChange: handleAddressChange,
      onChange: handleAddressSelect,
      onOpen: handleOpen,
      freeSolo: true,
      open: selectedTab === SEARCH_TAB.LOCATION,
    });

  const handleClickTab = useMemo(() => {
    return (tab: SEARCH_TAB) => {
      setIsComponentVisible(true);
      setSelectedTab(currentSelectedTab => {
        return tab !== SEARCH_TAB.LOCATION && tab === currentSelectedTab ? null : tab;
      });
    };
  }, [setIsComponentVisible]);

  useEffect(() => {
    if (focused) {
      handleClickTab(SEARCH_TAB.LOCATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  const datesTabIsOpened = isComponentVisible && selectedTab === SEARCH_TAB.DATES;

  // Allow to open dates tab from outside
  // Because we close the tab on click outside, we have to delay the opening
  useEffect(() => {
    if (isMobile) return;

    const handleOnOpenSearchDates = () => {
      if (datesTabIsOpened) return;
      setTimeout(() => handleClickTab(SEARCH_TAB.DATES), 100);
    };
    document.addEventListener(EVENT_OPEN_SEARCH_DATES, handleOnOpenSearchDates);
    return () => document.removeEventListener(EVENT_OPEN_SEARCH_DATES, handleOnOpenSearchDates);
  }, [isMobile, datesTabIsOpened, handleClickTab]);

  // action when closing dropdown
  useEffect(() => {
    if (!isComponentVisible) {
      if (selectedTab === SEARCH_TAB.DETAILS && guests) {
        onConfirmGuests?.(guests);
      }
      if (selectedTab === SEARCH_TAB.DATES && dates) {
        onConfirmDates?.(dates);
      }
      setSelectedTab(null);
    }
  }, [isComponentVisible, guests, onConfirmGuests, onConfirmDates, dates, selectedTab]);

  // we may hide some recent searches but require all options to calculate the index into the
  // results for getting option props (onClick handler)
  const allGroupOptions = groupedOptions as unknown as IGroupOption[];
  const groupOptions = limitRecentSearches(
    groupedOptions as unknown as IGroupOption[],
    intl.formatMessage({
      defaultMessage: 'Recent Searches',
      id: 'yLl55w',
      description: 'Global Header > Search',
    }),
    maxRecentSearches,
  );

  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    if (
      !address &&
      !isCampgroundSearch &&
      (!isPromoSearch || (isPromoSearch && !dates?.from)) &&
      dates?.from &&
      dates?.to &&
      searchResult.meta
    ) {
      const { lat, lng, city, state, country_name } = searchResult.meta;

      const searcherGeoLocation = [city, state, country_name].filter(Boolean).join(', ');

      if (searcherGeoLocation) {
        onChangeAddress?.(searcherGeoLocation);
        handleChangeDate?.(dates);
        setAddressValue(searcherGeoLocation);
        setSelectedTab(null);
        dispatch(
          applySearchFilter({
            [ESearchFilters.ADDRESS]: searcherGeoLocation,
            [ESearchFilters.DELIVERY_DETAILS]: encodeURIComponent(
              JSON.stringify({
                country: country_name,
                state,
                city,
              }),
            ),
            [ESearchFilters.DELIVERY_ADDRESS]: searcherGeoLocation,
            [ESearchFilters.DELIVERY_QUERY]: searcherGeoLocation,
            [ESearchFilters.DELIVERY_CENTER]: JSON.stringify([lng, lat]),
            [ESearchFilters.DATE_FROM]: dates?.from
              ? dayjs(dates.from).format(QUERYSTRING_DATE_FORMAT)
              : undefined,
            [ESearchFilters.DATE_TO]: dates?.to
              ? dayjs(dates.to).format(QUERYSTRING_DATE_FORMAT)
              : undefined,
            [ESearchFilters.FLEXIBLE_DAYS]: dates?.flexible_days
              ? String(dates.flexible_days)
              : undefined,
          }),
        );
      }
    } else if (
      !address &&
      !isCampgroundSearch &&
      (!isPromoSearch || (isPromoSearch && !dates?.from))
    ) {
      const inputEl = getInputProps() as any;
      inputEl.ref.current.focus();
      inputEl.onFocus();
    } else if (isCampgroundSearch && !dates?.from) {
      handleClickTab(SEARCH_TAB.DATES);
    } else {
      onSubmit?.();
      setSelectedTab(null);
    }
  };

  const dateTabText = formattedDates
    ? `${formattedDates} ${dates?.flexible_days ? ` (±${dates?.flexible_days})` : ''}`
    : '';

  const shouldOpenContentTab =
    selectedTab === SEARCH_TAB.DATES ||
    selectedTab === SEARCH_TAB.DETAILS ||
    (selectedTab === SEARCH_TAB.LOCATION && groupOptions.length > 0);

  return (
    <form
      className={clsx({
        ['absolute top-3']: !isPromoSearch && !isCampgroundSearch && !isSerpPage,
      })}
      onSubmit={handleSubmit}
      ref={ref}>
      <div
        className={clsx(
          'bg-white inline-flex rounded-full h-[48px] border-solid border-[1px] border-neutral-20',
        )}>
        {!isCampgroundSearch && (
          <LocationSearchInput getInputProps={getInputProps} alertStatus={alertStatus} />
        )}
        <DateTab
          onClick={() => handleClickTab(SEARCH_TAB.DATES)}
          selectedDates={dateTabText}
          isCampgroundSearch={isCampgroundSearch}
        />
        <GuestsTab
          onClick={() => handleClickTab(SEARCH_TAB.DETAILS)}
          guests={guests}
          isCampgroundSearch={isCampgroundSearch}
          isGuestOccupancyAvailable={isGuestOccupancyAvailable}
        />
        <SearchButton loading={loading} className={isSerpPage ? `!bg-[#B95B46]` : ''} />
      </div>

      {shouldOpenContentTab && (
        <div
          data-tab="dropdown_wrapper"
          className={clsx('relative', { ['z-10 max-w-30']: isPromoSearch || isCampgroundSearch })}>
          <div
            data-tab={selectedTab}
            className={`absolute top-0 overflow-hidden bg-white ${
              selectedTab !== SEARCH_TAB.DATES ? 'w-full' : ''
            } ${
              selectedTab === SEARCH_TAB.DETAILS &&
              !isCampgroundSearch &&
              rentalType === ERentalCategory.RV
                ? 'w-fit ml-[166px]'
                : ''
            } mt-6 rounded-box shadow-100 p-4 ${isCampgroundSearch ? 'right-0' : ''}`}>
            <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 7.5em)' }}>
              {!isCampgroundSearch && (
                <LocationSearchContent
                  open={selectedTab === SEARCH_TAB.LOCATION}
                  groupOptions={groupOptions}
                  allGroupOptions={allGroupOptions}
                  getListboxProps={getListboxProps}
                  getOptionProps={getOptionProps}
                />
              )}
              <DateContent
                dates={dates}
                onChangeDates={handleChangeDate}
                open={selectedTab === SEARCH_TAB.DATES}
              />
              {isCampgroundSearch || isGuestOccupancyAvailable ? (
                <DetailsContent
                  guests={guests}
                  onChangeGuests={onChangeGuests}
                  onConfirmGuests={onConfirmGuests}
                  onSubmit={onSubmit}
                  open={selectedTab === SEARCH_TAB.DETAILS}
                  isGuestOccupancyAvailable={isGuestOccupancyAvailable}
                />
              ) : (
                <GuestsContent
                  guests={guests}
                  onChangeGuests={onChangeGuests}
                  open={selectedTab === SEARCH_TAB.DETAILS}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default DesktopNavbarSearch;
